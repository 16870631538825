// extracted by mini-css-extract-plugin
export var button = "registration-page-module--button--heF6-";
export var ccs = "registration-page-module--ccs--Lq-nB";
export var contactDetailsIcon = "registration-page-module--contactDetailsIcon--q9Qwd";
export var contactForm = "registration-page-module--contactForm--dnHPC";
export var contactFormInputs = "registration-page-module--contactFormInputs--UEQnZ";
export var display = "registration-page-module--display--Ym5eq";
export var footer = "registration-page-module--footer--+te7j";
export var footerContent = "registration-page-module--footerContent--6dkv+";
export var footerL = "registration-page-module--footerL--jO9Rg";
export var footerLinks = "registration-page-module--footerLinks--JzM-y";
export var footerR = "registration-page-module--footerR--O5xwS";
export var formTitle = "registration-page-module--formTitle--67hkZ";
export var fullName = "registration-page-module--fullName--Y6un2";
export var globalCareSupply = "registration-page-module--globalCareSupply--NaWWe";
export var input = "registration-page-module--input--HcYjj";
export var input6 = "registration-page-module--input6--Y9pPW";
export var inputFieldWIthLabel = "registration-page-module--inputFieldWIthLabel--eUIde";
export var inputFieldWIthLabel9 = "registration-page-module--inputFieldWIthLabel9--UNDOQ";
export var inputLabel = "registration-page-module--inputLabel--usfAd";
export var inputLabel9 = "registration-page-module--inputLabel9--9xtwg";
export var inputRow = "registration-page-module--inputRow--bAohi";
export var inputRow4 = "registration-page-module--inputRow4--KsP3G";
export var passwordsShouldContainAMin = "registration-page-module--passwordsShouldContainAMin--xg7jr";
export var privacyPolicy = "registration-page-module--privacyPolicy--tP31Y";
export var registerContent = "registration-page-module--registerContent--ug8us";
export var registerSection = "registration-page-module--registerSection--uMuIC";
export var registrationPage = "registration-page-module--registrationPage--nUAV5";
export var visaIcon = "registration-page-module--visaIcon--FvDNV";